@font-face {
 font-family: "Circular";
 src: url("../public/fonts/CircularStd-Book.otf") format("truetype");
 font-weight: 400;
}

@font-face {
 font-family: "Circular";
 src: url("../public/fonts/CircularStd-Medium.otf") format("truetype");
 font-weight: 500;
}

@font-face {
 font-family: "Circular";
 src: url("../public/fonts/CircularStd-Bold.otf") format("truetype");
 font-weight: 700;
}

@font-face {
 font-family: "Circular";
 src: url("../public/fonts/CircularStd-Black.otf") format("truetype");
 font-weight: 900;
}

* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

body,
html {
 position: relative;
 overflow-x: hidden;
 max-width: 100vw;
}

body {
 font-family: "Circular";
 background-color: #fff;
}

.scrollHide {
 overflow: hidden;
 max-height: 100vh;
}

:root {
 --gutterLeft: 6.875rem;
 --gutterRight: 3.875rem;
}

.container {
 width: 100%;
 max-width: 1440px;
 margin: 0 auto;
}

.pointer {
 cursor: pointer;
}

.App {
 width: 100%;
 min-height: 100vh;
}

.header {
 font-size: 0.875rem;
 font-weight: 500;
 position: sticky;
 top: 0;
 z-index: 1;
 background-color: rgba(255, 255, 255, 0.75);
 backdrop-filter: blur(10px);
 padding: 0.9375rem var(--gutterRight) 0.9375rem var(--gutterLeft);
}

.flex {
 display: flex;
 align-items: center;
}

.sb {
 justify-content: space-between;
}

.col {
 flex-direction: column;
}

.al-fs {
 align-items: flex-start;
}

.jc-ce {
 justify-content: center;
}

.btn {
 min-width: 10.9375rem;
 height: 2.5rem;
 background: #000000;
 font-size: 0.875rem;
 border-radius: 0.3125rem;
 display: flex;
 font-weight: 900;
 align-items: center;
 color: #fff;
 width: max-content;
 cursor: pointer;
 justify-content: center;
 padding: 0.5rem 1rem;
 padding-right: 0.6rem;
 transition: all 0.25s ease-in-out;
 -webkit-transition: all 0.25s ease-in-out;
 -moz-transition: all 0.25s ease-in-out;
 -ms-transition: all 0.25s ease-in-out;
 -o-transition: all 0.25s ease-in-out;
 border: 0.0625rem solid #000;
}

.btnLarge {
 min-width: 13.3125rem;
 font-weight: 500;
 font-size: 1rem;
 line-height: 1.25rem;
}

.btn:hover {
 background-color: #fff;
 color: #000;
}

.btn:hover svg path {
 stroke: #000;
}

.btn svg path {
 transition: all 0.25s ease-in-out;
 -webkit-transition: all 0.25s ease-in-out;
 -moz-transition: all 0.25s ease-in-out;
 -ms-transition: all 0.25s ease-in-out;
 -o-transition: all 0.25s ease-in-out;
}

.btn svg {
 margin-left: 0.5rem;
}

.twoGrid {
 display: grid;
 grid-template-columns: 1fr 1fr;
}

.link {
 white-space: nowrap;
 cursor: pointer;
 position: relative;
 width: max-content;
}

.brand {
 position: relative;
 z-index: 1000;
}

.light {
 color: #ababab;
}

.break {
 margin-right: 0.25rem;
 margin-left: 0.25rem;
}

.language {
 margin-left: 1.625rem;
 margin-right: 1.3125rem;
}

.how {
 margin-left: 1.3125rem;
}

.hero {
 min-height: 31.25rem;
 align-items: center;
 background: #f8f8f8;
}

.heroText {
 font-weight: 900;
 font-size: 2.5rem;
 line-height: 3.1875rem;
 max-width: 18ch;
 color: #000000;
}

.law-person2 {
 height: 8.5625rem;
}

.subText {
 font-weight: 500;
 font-size: 1.25rem;
 line-height: 1.875rem;
 /* or 150% */
 margin-top: 1.3125rem;
 margin-bottom: 1.3125rem;
 color: #494949;
 max-width: 40ch;
}

.moneyBack {
 margin-top: 0.625rem;
 font-size: 0.75rem;
}

.moneyBack svg {
 margin-right: 0.625rem;
}

.rightSection {
 background-image: url("./assets/peach.png");
 background-size: cover;
 background-color: #000000;
 position: relative;
 height: 100%;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.user {
 background: #ffffff;
 border-radius: 0.3125rem;
 width: max-content;
 padding: 0.5625rem 1.5rem;
 margin-left: 2rem;
 transform: translateY(-50%);
 -webkit-transform: translateY(-50%);
 -moz-transform: translateY(-50%);
 -ms-transform: translateY(-50%);
 -o-transform: translateY(-50%);
}

.user svg {
 margin-left: 0.3125rem;
}

.lawPerson {
 object-fit: contain;
 max-width: 30.75rem;
 margin: auto;
 width: 100%;
 height: 100%;
}

.rightSectionContent {
 padding-top: 2.9375rem;
 padding-left: 3.125rem;
 padding-right: 3.125rem;
 padding-bottom: 6.125rem;
 position: relative;
 display: flex;
 align-items: center;
 justify-content: center;
}

.rect {
 height: 4.5rem;
 background: #d5ffe5;
 border-radius: 0.3125rem;
 -webkit-border-radius: 0.3125rem;
 -moz-border-radius: 0.3125rem;
 -ms-border-radius: 0.3125rem;
 -o-border-radius: 0.3125rem;
 width: 100%;
}

.legalTxt {
 font-weight: 500;
 font-size: 2.1875rem;
 line-height: 2.75rem;
 text-align: center;
 color: #000000;
 max-width: 40ch;
 text-align: center;
 margin-bottom: 4.0625rem;
}

.section1 {
 justify-content: center;
 align-items: center;
 padding: 0 10rem;
}

.section1 img {
 aspect-ratio: inherit;
}

.ham {
 position: relative;
 z-index: 1000;
 width: 2.5rem;
 height: 2.5rem;
 display: flex;
 align-items: center;
 justify-content: center;
}

.content {
 position: fixed;
 height: 100vh;
 left: 0;
 top: 0;
 width: 100%;
 z-index: 999;
 overflow: auto;
 padding-top: 4.375rem;
 background-color: #fff;
 transition: all 0.4s ease;
 -webkit-transition: all 0.4s ease;
 -moz-transition: all 0.4s ease;
 -ms-transition: all 0.4s ease;
 -o-transition: all 0.4s ease;
}

.content .link,
.content .language {
 font-weight: 500;
 font-size: 0.875rem;
 line-height: 1.125rem;
 margin: 1rem 0;
 margin-left: 3.3125rem;
}

.section1 {
 margin-top: 6.875rem;
 margin-bottom: 4.375rem;
}

.clarify {
 background: #fafafa;
 min-height: 28.125rem;
 max-width: 1100px;
 margin: auto;
 overflow: hidden;
 border-radius: 3.125rem;
 -webkit-border-radius: 3.125rem;
 -moz-border-radius: 3.125rem;
 -ms-border-radius: 50px;
 -o-border-radius: 50px;
 margin-top: 9.375rem;
 margin-bottom: 9.375rem;
}

.caseFlow {
 width: 100%;
 max-width: 59.375rem;
}

.chanceBtn {
 white-space: nowrap;
 padding: 0;
 font-weight: 900;
 font-size: 1.25rem;
 line-height: 1.5625rem;
 max-width: 17.6875rem;
 height: 3.75rem;
 background-color: #000;
 color: #fff;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 0.5rem;
 -webkit-border-radius: 0.5rem;
 -moz-border-radius: 0.5rem;
 -ms-border-radius: 0.5rem;
 -o-border-radius: 0.5rem;
 margin-top: 22%;
}

.clarifyText {
 font-weight: 900;
 font-size: 2.1875rem;
 line-height: 2.8125rem;
 color: #000000;
 margin-bottom: 1.375rem;
 max-width: 22ch;
}

.clarifyTextSection {
 padding: 3rem;
 padding-right: 1rem;
}

.chanceSection {
 padding: 3rem;
 background-image: url("./assets/clarify.png");
 background-repeat: no-repeat;
 background-size: cover;
}

.cheaper {
 font-weight: 500;
 font-size: 2.1875rem;
 line-height: 2.75rem;
 text-align: center;
 max-width: 50ch;
 color: #000000;
 margin: auto;
 margin-bottom: 5rem;
 margin-top: 6.25rem;
}

.startAnalysis {
 max-width: 500px;
 width: 100%;
 margin-top: 35px;
 justify-content: space-between;
}

.compareGrid {
 background-color: #fafafa;
 margin: 0 6.25rem;
 grid-gap: 2.5rem;
}

.item {
 padding: 1.875rem 3.125rem;
 border-top: 2px solid #e2e2e2;
 font-weight: 500;
 font-size: 1.25rem;
 line-height: 1.5625rem;
}

.flinnCompareHeader {
 min-height: 100%;
 align-items: flex-end;
}

.worldWide .leftSection,
.service .leftSection {
 padding-top: 2rem;
 padding-bottom: 2rem;
 padding-right: 2rem;
}

.analysis-img {
 height: auto;
}

.worldWide .rightSectionContent {
 padding-left: 0;
 padding-right: 0;
 max-height: 380px;
 overflow: hidden;
 margin: 0 auto;
 align-items: flex-start;
 justify-content: center;
}

.worldWide .rightSection {
 justify-content: flex-end;
 align-items: flex-end;
}

.service .rightSection {
 background-image: url("./assets/boxes.png");
}

.accordion-title {
 cursor: pointer;
}

.accordion-item {
 max-width: 500px;
 border-bottom: 1px solid #000000;
}

.accordion-title {
 padding: 16px;
 padding-left: 0;
 font-weight: 500;
 font-size: 1.25rem;
 line-height: 1.5625rem;
 color: #000000;
}

.content-container {
 padding-bottom: 1rem;
}

.accordion-item:last-child .content-container {
 padding-bottom: 0;
}

.accordion-content {
 max-height: 0px;
 overflow-y: hidden;
 transition: max-height 250ms cubic-bezier(0.25, 1, 0.5, 1);
 -webkit-transition: max-height 250ms cubic-bezier(0.25, 1, 0.5, 1);
 -moz-transition: max-height 250ms cubic-bezier(0.25, 1, 0.5, 1);
 -ms-transition: max-height 250ms cubic-bezier(0.25, 1, 0.5, 1);
 -o-transition: max-height 250ms cubic-bezier(0.25, 1, 0.5, 1);
}

.headerItem {
 height: 10.625rem;
 font-weight: 900;
 font-size: 2.5rem;
 line-height: 3.1875rem;
 color: #000000;
 width: 100%;
 padding-left: 3.1875rem;
 padding-right: 2rem;
}

.flinnText {
 align-self: center;
}

.otherLawyersHeader {
 height: 100%;
}

.footerText {
 font-weight: 500;
 font-size: 0.875rem;
 line-height: 1.5625rem;
 color: #50555c;
 margin-top: 3.5rem;
}

.footerGrid {
 display: grid;
 grid-template-columns: 1.5fr 1fr 1.25fr 1.25fr;
}

footer {
 background: #f8f8f8;
 padding: 0 10rem;
 padding-bottom: 2.625rem;
 padding-top: 8.25rem;
 margin-top: 6.875rem;
}

.footerHeader {
 font-weight: 900;
 font-size: 2.5rem;
 margin-bottom: 0.8125rem;
 line-height: 3.1875rem;
}

.footerHeader,
.footerSmHeader {
 height: 3rem;
}

.footerSmHeader {
 font-weight: 700;
 font-size: 1.375rem;
 line-height: 1.75rem;
}

.footerLink {
 font-weight: 500;
 font-size: 1.125rem;
 line-height: 1.4375rem;
 display: block;
 color: #50555c;
 margin-bottom: 1.0625rem;
 position: relative;
 width: max-content;
 cursor: pointer;
}

.footerDetails {
 font-weight: 500;
 font-size: 1.125rem;
 line-height: 1.4375rem;
 margin-bottom: 0.6875rem;
 color: #50555c;
}

.underline {
 text-decoration: underline;
}

.solveBtn {
 width: 100%;
 max-width: 100%;
}

.hide-pc {
 display: none;
}

.solve {
 padding: 1.125rem;
 text-align: center;
 font-weight: 500;
 font-size: 0.75rem;
 line-height: 0.9375rem;
 color: #878787;
 background: #e1e1e1;
}

.cc {
 font-weight: 500;
 font-size: 0.75rem;
 line-height: 0.9375rem;
 margin-top: 1.25rem;
 margin-bottom: 0.5rem;
 color: #50555c;
}

.exit {
 transition-delay: 1s;
 transform: translateY(-100%);
 -webkit-transform: translateY(-100%);
 -moz-transform: translateY(-100%);
 -ms-transform: translateY(-100%);
 -o-transform: translateY(-100%);
}

.leftSection {
 max-width: calc(1440px / 2);
 padding: 0 0 0 var(--gutterLeft);
 margin-left: auto;
 width: 100%;
}

.rightSectionContent {
 max-width: calc(1440px / 2);
 margin-right: auto;
 padding-left: 114px;
}

.leftSection,
.lawPerson {
 animation-delay: 3000ms !important;
}

.footerBg {
 background: #f8f8f8;
}

@media (max-width: 1400px) {
 :root {
  font-size: 14px;
 }

 .rightSectionContent {
  padding-left: 10%;
  padding-bottom: 10%;
  margin: auto;
 }
}

@media (max-width: 1024px) {
 :root {
  --gutterLeft: 3.5rem;
  --gutterRight: 3.5rem;
 }

 footer {
  padding: 4rem 6rem 2rem 6rem;
 }

 .compareGrid {
  margin: 0 3.125rem;
 }

 .clarify {
  margin-left: 5rem;
  margin-right: 5rem;
 }

 .cheaper {
  padding: 0 2rem;
 }

 .section1 {
  padding: 0 5rem;
 }
}

@media (max-width: 950px) {
 .twoGrid {
  grid-template-columns: 1fr;
 }

 .hero {
  grid-template-columns: 1fr 1fr;
  min-height: 25rem;
 }

 footer {
  padding-left: 3rem;
  padding-right: 3rem;
 }

 .footerGrid {
  grid-template-columns: 1fr 1fr 1fr 1fr;
 }

 .chanceSection {
  background-image: url("./assets/clarify-mobile.png");
  background-position: -100%;
  min-height: 18.75rem;
 }

 .chanceBtn {
  margin-top: 2rem;
 }

 .clarify {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
 }
}

@media (max-width: 767px) {
 .hero {
  grid-template-columns: 1fr;
  min-height: 25rem;
  padding: 0;
  grid-gap: 0;
 }

 .heroText {
  font-size: 30px;
  line-height: 38px;
 }

 .subText {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-top: 12px;
 }

 .hide-pc {
  display: block;
 }

 .header {
  height: 70px;
 }

 .hero .rightSection {
  height: 339px;
 }

 .rightSectionContent {
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 50px;
  padding-bottom: 44px;
 }

 .hero .leftSection {
  padding-top: 55px;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 44px;
 }

 .footerGrid {
  grid-template-columns: 1fr;
 }

 .caseFlow {
  display: none;
 }

 .hide-mobile {
  display: none;
 }

 .legalTxt {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
 }

 .worldWide .leftSection,
 .service .leftSection {
  padding-top: 38px;
  padding-bottom: 53px;
  padding-left: 43px;
  padding-right: 43px;
 }

 .accordion-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-top: 19px;
  padding-bottom: 15px;
 }

 .clarify {
  margin: 0;
  margin-top: 3rem;
 }

 .clarifyText {
  font-weight: 900;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
  max-width: none;
 }

 .cheaper {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
 }

 .compareGrid {
  margin-left: 45px;
  margin-right: 45px;
 }

 .flinnText {
  font-size: 16px;
  font-weight: 900;
 }

 .law-person2 {
  height: 70px;
 }

 .headerItem {
  padding-left: 26px;
  height: 85px;
 }

 .item {
  padding: 18px 24px;
  font-size: 14px;
 }

 .accordion-item,
 .startAnalysis {
  max-width: 100%;
 }

 .section1 {
  margin-top: 48px;
  margin-bottom: 62px;
  padding: 0 24px;
 }

 .legalTxt {
  margin-bottom: 0;
 }

 .clarifyTextSection {
  padding-top: 16px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 69px;
 }

 .clarifyTextSection .btn {
  max-width: 100%;
  width: 100%;
 }

 .chanceSection {
  height: 300px;
  background-position: 0;
 }

 .chanceBtn {
  width: 200px;
  height: 50px;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  margin-top: 70px;
 }

 .cheaper {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 40px;
 }

 footer {
  margin-top: 20px;
  padding-top: 58px;
  padding-left: 53px;
 }

 .footerHeader {
  font-weight: 900;
  font-size: 30px;
  line-height: 38px;
  color: #000000;
 }

 .footerSmHeader {
  margin-top: 36px;
 }

 .btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 45px;
 }

 .startAnalysis {
  font-size: 14px;
 }

 .startAnalysis svg {
  width: 18px;
 }

 .service .heroText,
 .worldWide .heroText {
  max-width: 14ch;
  white-space: break-spaces;
 }

 .service .rightSection {
  background-size: contain;
  background-position-x: -10px;
 }

 .footerText {
  margin-top: 1.5rem;
 }
}

a {
 text-decoration: none;
}

.open {
 transform: translateY(0);
 -webkit-transform: translateY(0);
 -moz-transform: translateY(0);
 -ms-transform: translateY(0);
 -o-transform: translateY(0);
}

.close {
 transform: translateY(-100%);
 -webkit-transform: translateY(-100%);
 -moz-transform: translateY(-100%);
 -ms-transform: translateY(-100%);
 -o-transform: translateY(-100%);
}
